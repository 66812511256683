import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import {
  authorPicture,
  authorName,
  authorContainer,
} from "./author.module.scss"

function Author(props) {
  let { data, className, ...rest } = props
  let classes = `${className ? className : ""} ${authorContainer}`
  return (
    <div className={classes} {...rest}>
      <GatsbyImage
        alt=""
        image={data.picture.localFile.childImageSharp.gatsbyImageData}
        objectFit="cover"
        objectPosition="50% 50%"
        className={authorPicture}
      />
      <div className={authorName}>{data.name}</div>
    </div>
  )
}

export default Author
