import React from "react"
import { graphql } from "gatsby"
import SEO from "../seo"
import Layout from "../layout/layout"
import { NewsPage } from "../components/enum/pages"
import {
  container,
  content,
  publicationDate,
  title,
  topImage,
} from "../styles/news.module.scss"
import { injectIntl } from "gatsby-plugin-intl"
import { GatsbyImage } from "gatsby-plugin-image"
import Category from "../components/category"
import Markdown from "react-markdown"
import Author from "../components/author"
export const pageQuery = graphql`
  query ($id: String!) {
    strapiArticle(id: { eq: $id }) {
      title
      content
      description
      publishedAt
      status
      strapiId
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
      category {
        name
      }
      author {
        name
        picture {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

function BlogPost(props) {
  let { data, intl } = props
  return (
    <div className={container}>
      <GatsbyImage
        alt=""
        image={data.image.localFile.childImageSharp.gatsbyImageData}
        className={topImage}
        imgStyle={{
          objectFit: "contain",
          objectPosition: "center",
        }}
      />
      <Category>{data.category.name}</Category>
      <h1 className={title}>{data.title}</h1>
      <div className={publicationDate}>{intl.formatDate(data.publishedAt)}</div>
      <Markdown className={content} children={data.content} />
      <Author data={data.author} />
    </div>
  )
}

function News(props) {
  const { intl, data } = props
  return (
    <Layout active={NewsPage}>
      <SEO
        seo={{
          metaTitle: data.strapiArticle.title,
          metaDescription: data.strapiArticle.description,
          locale: intl.locale,
        }}
      />
      <BlogPost data={data.strapiArticle} intl={intl} />
    </Layout>
  )
}

export default injectIntl(News)
